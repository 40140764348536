@use "../../../styles/variables";
@use "../../../styles/mixin";

.contentWrapper {
  padding: 40px 0;

  @include mixin.devices(tablet) {
    padding: 80px 0 64px 0;
  }

  @include mixin.devices(desktop) {
    margin: 0 auto;
    padding: 76px 0 24px 0;
  }
}

.contentWrapper h1 {
  margin: 0 0 15px 0;

  color: variables.$main-text-color;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;

  @include mixin.devices(tablet) {
    margin-bottom: 30px;

    font-size: 40px;
    font-weight: 700;
  }

  @include mixin.devices(desktop-plus) {
    margin-bottom: 30px;

    font-size: 64px;
  }
}

.contentWrapper p em {
  margin: 0 0 5px 0;
  display: inline-block;

  color: variables.$text-italic-color;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.6;
  max-width: 230px;
  white-space: pre-wrap;

  @include mixin.devices(tablet) {
    margin: 0 0 15px 0;

    font-size: 16px;
    max-width: 300px;
  }
}

.contentWrapper ul {
  padding: 0;
  margin: 0;
  color: variables.$text-gray-color;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.6;
  list-style-type: none;

  @include mixin.devices(tablet) {
    font-size: 18px;
  }

  li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 18px;

    @include mixin.devices(tablet) {
      margin-bottom: 16px;
      padding-left: 28px;
    }

    @include mixin.devices(desktop) {
      padding-left: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 5.5px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: variables.$main-text-color;

      @include mixin.devices(tablet) {
        top: 7px;
        width: 14px;
        height: 14px;
      }
    }
  }
}

.contentWrapper ul li p {
  margin: 0;
}

.contentWrapper p {
  margin: 15px 0;

  color: variables.$text-gray-color;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  white-space: pre-line;

  @include mixin.devices(tablet) {
    margin: 30px 0;

    font-size: 18px;
    line-height: 1.6;
  }
}

.contentWrapper h2 {
  margin: 64px 0 15px 0;

  color: variables.$main-text-color;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;

  @include mixin.devices(tablet) {
    margin: 124px 0 30px 0;

    font-size: 40px;
    font-weight: 700;
  }

  @include mixin.devices(desktop-plus) {
    margin: 124px 0 64px 0;

    font-size: 64px;
  }
}

.contentWrapper h3 {
  margin: 15px 0 15px 0;

  color: variables.$main-text-color;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.3;

  @include mixin.devices(tablet) {
    margin: 30px 0 20px 0;

    font-size: 20px;
    font-weight: 700;
  }

  @include mixin.devices(desktop) {
    margin: 64px 0 30px 0;
  }
}

.contentWrapper a {
  color: variables.$link-color;
  text-decoration: underline;
}

.contentWrapper img {
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  max-width: 90%;
  height: auto;

  @include mixin.devices(tablet) {
    margin-top: 64px;
    max-width: 100%;
  }

  @include mixin.devices(desktop) {
    margin-top: 48px;
  }
}

.mobileAppWrapper {
  position: relative;

  margin-top: 190px;
  padding: 100px 0 30px;
  background-color: variables.$game-grid-rows;
  width: 100vw;
  @include mixin.devices(tablet) {
    margin: 0 auto;
    padding: 170px 0 40px;
    margin-top: 190px;

    border-radius: 15px;
    left: 0;
    width: 688px;
  }

  @include mixin.devices(desktop) {
    display: none;
  }
}

.marketLinkBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.mobileAppBox {
  margin: 0 auto;
  margin-bottom: 23px;
  padding: 0 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 320px;

  @include mixin.devices(tablet) {
    padding: 0;
    max-width: 396px;
  }
}

.mobileAppInfoBox {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mobileAppRatingTitle {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
  line-height: normal;
  color: variables.$gray-color;
}

.mobileAppRatingSubitle {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  color: variables.$gray-secondary-text-color;
}

.mobileAppImage {
  position: absolute;
  top: -160px;
  left: 50%;
  transform: translateX(-50%);

  width: 199px;
  height: auto;

  @include mixin.devices(tablet) {
    top: -180px;
    width: 271px;
  }
}

.rulesFooterWrapper {
  text-align: center;
}

.rulesFooterSubTitle {
  margin: 0 0 15px 0;
  font-size: 17px;
  font-weight: 600;
  line-height: normal;
  color: variables.$main-text-color;

  @include mixin.devices(tablet) {
    margin-bottom: 20px;

    font-size: 32px;
    font-weight: 700;
    line-height: 1.6;
  }
}

.rulesFooterSubText {
  margin: 0 0 20px 0;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  color: variables.$text-gray-color;

  @include mixin.devices(tablet) {
    margin-bottom: 30px;

    font-size: 18px;
  }
}

.ratingTitle {
  margin: 14px 0 6px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: variables.$text-gray-color;

  @include mixin.devices(tablet) {
    margin-bottom: 10px;

    font-size: 20px;
  }
}

.ratingSubtitle {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: variables.$gray-secondary-text-color;
}

.ratingImg {
  margin: 0 auto;
}

.ratingLinkBox {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @include mixin.devices(tablet) {
    margin: 30px auto;

    gap: 30px;
  }
}

.gameContainer {
  height: auto;
  margin: 0 auto;
  padding: 0 4px;

  @include mixin.devices(tablet) {
    padding: 0;
    width: 688px;
  }

  @include mixin.devices(desktop) {
    width: calc((100% / 6) * 4);
  }

  @include mixin.devices(desktop-xl) {
    width: 1290px;
  }
}

.loaderContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playMainWrapper {
  margin: 0 auto;

  @include mixin.devices(desktop) {
    padding: 0;
    margin-top: 80px;
  }
}

.blueLine {
  padding: 24px 0;
  position: relative;
  width: 100vw;
  background: linear-gradient(131.27deg, #51adf5 8.95%, #2196f3 85.94%);

  @include mixin.devices(tablet) {
    margin: 0 auto;
    padding-bottom: 74px;

    border-radius: 24px;
    left: 0;
    width: 688px;
  }

  @include mixin.devices(desktop) {
    margin-bottom: 57px;
    padding: 55px 48px;

    width: 1000px;
  }
}

.downLoadText {
  font-size: 30px;
  font-weight: 900;
  white-space: wrap;
  color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-content: center;
  place-self: center;
  width: 300px;

  position: absolute;
  top: 60px;

  left: 50%;
  transform: translateX(-50%);
  @include mixin.devices(tablet) {
    top: 4px;
    font-size: 54px;
    width: 550px;
  }

  @include mixin.devices(tablet) {
    left: 2%;
    transform: translateX(0);
    top: 14px;
    font-size: 45px;
    width: 550px;
  }
}

.downLoadTextSecond {
  font-size: 30px;
  font-weight: 900;
  white-space: wrap;
  color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-content: center;
  place-self: center;
  width: 300px;

  position: absolute;
  top: 210px;

  left: 50%;
  transform: translateX(-50%);
  @include mixin.devices(tablet) {
    top: 270px;
    font-size: 54px;
    width: 550px;
  }
  @include mixin.devices(desktop) {
    display: none;
  }
}

.playBestWrapper {
  @include mixin.devices(desktop) {
    display: flex;
    gap: 20px;
    height: 140px;
  }
}

.playBestBox {
  margin-bottom: 17px;
  @include mixin.devices(desktop) {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }
}

.playBestText {
  margin-bottom: 4px;
  text-align: center;
  font-size: 25px;
  font-weight: 900;
  line-height: 1;
  color: variables.$white;
  text-transform: uppercase;

  @include mixin.devices(tablet) {
    font-size: 32px;
  }

  @include mixin.devices(desktop) {
    font-size: 25px;
  }
}

.playOnlineText {
  text-align: center;
  font-size: 36px;
  font-weight: 900;
  line-height: 1;
  color: variables.$white;
  text-transform: uppercase;
  @include mixin.devices(tablet) {
    font-size: 45px;
  }
  @include mixin.devices(desktop) {
    font-size: 36px;
  }
}

.playSudokuText {
  margin-bottom: 280px;
  text-align: center;
  font-size: 50px;
  font-weight: 900;
  line-height: 1;
  color: variables.$white;
  text-transform: uppercase;

  @include mixin.devices(tablet) {
    font-size: 60px;
  }
  @include mixin.devices(desktop) {
    font-size: 65px;
  }
}

.playSudokuImg {
  display: block;
  width: 231px;
  height: auto;
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);

  @include mixin.devices(tablet) {
    width: 280px;
    top: 160px;
  }

  @include mixin.devices(desktop) {
    width: 341px;
    top: -90px;
    left: 80%;
    transform: translateX(-50%);
  }
}

.marketLinkWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  @include mixin.devices(tablet) {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include mixin.devices(desktop) {
    position: absolute;
    bottom: 24px;
    left: 170px;
    transform: translateX(0);
  }
}
