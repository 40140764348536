@use "../../../styles/mixin";

.imgBtn {
  width: 124px;
  height: 37px;

  @include mixin.devices(phone-tablet) {
    width: 145px;
    height: 40px;
  }

  @include mixin.devices(tablet) {
    width: 190px;
    height: 54px;
  }
}
