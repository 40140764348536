@use "../../../styles/variables";
@use "../../../styles/mixin";

.rulesWrapperContent {
  padding: 64px 0 40px 0;

  @include mixin.devices(tablet) {
    padding: 124px 0 124px 0;
  }

  @include mixin.devices(desktop) {
    padding: 60px 0 124px 0;
    margin: 0 auto;
    width: 950px;
  }
}

.titleRule {
  margin: 0 0 10px 0;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: variables.$main-text-color;

  @include mixin.devices(tablet) {
    margin-bottom: 16px;

    font-size: 18px;
  }
}

.subTitleRule {
  margin: 0 0 24px 0;

  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: variables.$main-text-color;

  @include mixin.devices(tablet) {
    margin-bottom: 30px;

    font-size: 40px;
    font-weight: 700;
  }

  @include mixin.devices(desktop) {
    margin-bottom: 64px;

    font-size: 64px;
  }
}

.textRule {
  margin: 0 0 16px 0;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  color: variables.$text-gray-color;

  @include mixin.devices(tablet) {
    margin-bottom: 30px;

    font-size: 18px;
  }
}

.titleUp {
  margin: 0 0 15px 0;

  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: variables.$main-text-color;
  text-transform: uppercase;

  @include mixin.devices(tablet) {
    margin-bottom: 20px;

    font-size: 20px;
    font-weight: 700;
  }
}

.listRule {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;

  @include mixin.devices(tablet) {
    margin-bottom: 30px;
  }
  @include mixin.devices(desktop) {
    margin-bottom: 24px;
  }
}

.listItem {
  display: flex;
  align-items: baseline;
}

.listDots {
  width: 8px;
  height: 8px;
  margin-right: 10px;

  @include mixin.devices(tablet) {
    width: 14px;
    height: 14px;
    margin-right: 12px;
  }
  @include mixin.devices(desktop) {
    margin-right: 14px;
  }
}

.listItemText {
  margin: 0 0 5px 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  color: variables.$text-gray-color;

  @include mixin.devices(tablet) {
    font-size: 18px;
    margin-bottom: 12px;
  }
  @include mixin.devices(desktop) {
    margin-bottom: 16px;
  }
}

.coloredBox {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 15px;
  background-color: variables.$game-grid-rows;

  display: flex;
  flex-direction: column;
  gap: 5px;

  @include mixin.devices(tablet) {
    margin-bottom: 64px;
    padding: 22px;

    flex-direction: row;
    gap: 15px;
  }
  @include mixin.devices(desktop) {
    margin-bottom: 48px;
    padding: 16px;

    justify-content: center;
  }
}

.cautionTitle {
  margin: 0;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6;
  color: variables.$main-text-color;
  text-transform: uppercase;

  @include mixin.devices(tablet) {
    font-size: 18px;
  }
}

.cautionText {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  color: variables.$text-gray-color;

  @include mixin.devices(tablet) {
    font-size: 18px;
  }
}

.cautionImage {
  margin: 0 auto;

  display: block;
  width: 288px;
  height: auto;

  @include mixin.devices(tablet) {
    width: 380px;
  }
}
